<template>
  <base-section
    id="demo-request"
  >
    <base-section-heading title="Получить демо-версию и узнать стоимость внедрения">
    </base-section-heading>
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="d-flex justify-center"
          cols="12"
        >
          <v-card
            class="not-mobile-width"
            max-width="600"
          >
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Имя"
                  required
                />

                <v-text-field
                  v-model="surname"
                  :rules="surnameRules"
                  label="Фамилия"
                  required
                />
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Почта"
                  required
                />
                <v-textarea
                  v-model="text"
                  label="Текст"
                />
                <vue-hcaptcha
                  :sitekey="sitekey"
                  @verify="onVerify"
                  @expired="onExpire"
                  @challenge-expired="onChallengeExpire"
                  @error="onError"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :loading="mailSend"
                @click="checkAndSend"
              >
                Отправить запрос
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="responseDialog.show"
      max-width="450"
    >
      <v-card>
        <v-card-text class="pt-6">
          {{ responseDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="responseDialog.show = false"> ok </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </base-section>
</template>

<script>
  import axios from 'axios'
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

  export default {
    name: 'demo-request',
    components: { VueHcaptcha },
    data () {
      return {
        sitekey: process.env.NODE_ENV === 'development' ? '10000000-ffff-ffff-ffff-000000000001' : '223a8483-eaed-41de-9c34-7e92f07daebb',
        url: process.env.NODE_ENV === 'development' ? 'http://192.168.0.29:6566/api/sendmail' : 'https://api.itmuse.ru/api/sendmail',
        mailSend: false,
        responseDialog: {
          show: false,
          text: null,
          state: null,
        },
        eKey: null,
        token: null,
        verified: false,
        valid: false,
        name: null,
        email: null,
        surname: null,
        text: null,
        nameRules: [
          v => !!v || 'Имя обязательно',
          v => (v && v.length <= 10) || 'Имя должно быть меньше 10 символов',
        ],
        surnameRules: [
          v => !!v || 'Фамилия обязательно',
          v => (v && v.length <= 15) || 'Фамилия должна быть меньше 15 символов',
        ],
        textRules: [
          v => !!v || 'Сопроводительный текст обязателен',
        ],
        emailRules: [
          v => !!v || 'Почта обязательна',
          v => /.+@.+\..+/.test(v) || 'Почта должна быть валидной',
        ],
      }
    },
    methods: {
      onExpire () {
        this.verified = false
        this.token = null
        this.eKey = null
        this.expired = true
        console.log('Expired')
      },
      onChallengeExpire () {
        this.verified = false
        this.token = null
        this.eKey = null
        this.expired = true
        console.log('Challenge expired')
      },
      onError (err) {
        this.token = null
        this.eKey = null
        this.error = err
        console.log(`Error: ${err}`)
      },
      onVerify (token, ekey) {
        this.verified = true
        this.eKey = ekey
        this.token = token
        console.log(`token: ${token}`)
      },
      async checkAndSend () {
        this.$refs.form.validate()
        if (this.valid && this.verified && this.token) {
          this.mailSend = true
          axios({
            method: 'post',
            url: this.url, // '/api/RespFromCategoryMsg',
            data: {
              Family: this.surname,
              Name: this.name,
              Email: this.email,
              Msg: this.text,
              token: this.token,
            },
          }).then((resp) => {
            this.mailSend = false
            this.responseDialog.show = true
            this.responseDialog.text = resp.data
            this.surname = null
            this.name = null
            this.email = null
            this.text = null
            this.token = null
          }).catch((err) => {
            this.mailSend = false
            this.responseDialog.show = true
            this.responseDialog.text = err.response.data
            this.surname = null
            this.name = null
            this.email = null
            this.text = null
            this.token = null
          })
        }
      },
    },
  }
</script>

<style>
@media screen and (min-width: 640px) {
  .not-mobile-width {
    width:600px;
  }
}

</style>
